export const POI_MANUFACTURER_KEYWORDS = {
  'grand-river': 'Grand River',
  'rockwood': 'Rockwood',
  'keystone': 'Keystone',
  'dutchmen': 'Dutchmen',
  'heartland': 'Heartland',
  'grand-design': 'Grand Design',
  'starcraft': 'Starcraft',
  'coleman': 'Coleman',
  'crossroads': 'Crossroads',
  'palomino': 'Palomino',
  'kz': 'KZ',
  'prime-time': 'Prime Time',
  'cruiser-rv': 'Cruiser RV',
  'highland-ridge': 'Highland Ridge',
  'nucamp': 'Nucamp',
  'thor-motor-coach': 'Thor Motor Coach',
  'fleetwood': 'Fleetwood',
  'entegra-coach': 'Entegra Coach',
  'airstream': 'Airstream',
  'winnebago': 'Winnebago',
  'forest-river': 'Forest River',
  'jayco': 'Jayco',
  'gulf-stream': 'Gulf Stream',
  'coachmen': 'Coachmen',
}
Object.freeze(POI_MANUFACTURER_KEYWORDS)
export type POI_MANUFACTURER_KEYWORDS = (typeof POI_MANUFACTURER_KEYWORDS)[keyof typeof POI_MANUFACTURER_KEYWORDS]

export const POI_RENTAL_TYPE_KEYWORDS_EN_TO_FR = {
  'motorhome': 'motorise',
  'class-a': 'classe-a',
  'class-b': 'classe-b',
  'class-c': 'classe-c',
  'campervan': 'campervan',
  'trailer': 'roulotte',
  'fifth-wheel': 'sellette',
  'travel-trailer': 'caravane-de-voyage',
  'toy-hauler': 'roulotte-cargo',
  'micro-trailer': 'mini-roulotte',
}
Object.freeze(POI_RENTAL_TYPE_KEYWORDS_EN_TO_FR)
export type POI_RENTAL_TYPE_KEYWORDS_EN_TO_FR = (typeof POI_RENTAL_TYPE_KEYWORDS_EN_TO_FR)[keyof typeof POI_RENTAL_TYPE_KEYWORDS_EN_TO_FR]

export const POI_RENTAL_TYPE_KEYWORDS_FR_TO_EN = {
  'motorise': 'motorhome',
  'classe-a': 'class-a',
  'classe-b': 'class-b',
  'classe-c': 'class-c',
  'campervan': 'campervan',
  'roulotte': 'trailer',
  'sellette': 'fifth-wheel',
  'caravane-de-voyage': 'travel-trailer',
  'roulotte-cargo': 'toy-hauler',
  'mini-roulotte': 'micro-trailer',
}
Object.freeze(POI_RENTAL_TYPE_KEYWORDS_FR_TO_EN)
export type POI_RENTAL_TYPE_KEYWORDS_FR_TO_EN = (typeof POI_RENTAL_TYPE_KEYWORDS_FR_TO_EN)[keyof typeof POI_RENTAL_TYPE_KEYWORDS_FR_TO_EN]

export const POI_RENTAL_TYPE_KEYWORDS = [
  // English keywords
  ...Object.keys(POI_RENTAL_TYPE_KEYWORDS_EN_TO_FR),

  // French keywords
  ...Object.keys(POI_RENTAL_TYPE_KEYWORDS_FR_TO_EN),
]
Object.freeze(POI_RENTAL_TYPE_KEYWORDS)
export type POI_RENTAL_TYPE_KEYWORDS = (typeof POI_RENTAL_TYPE_KEYWORDS)[number]
